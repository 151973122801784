import React from 'react';

import * as Yup from 'yup';
import api from '@ellure/api-client-admin';
import { Material, MaterialBatch, Product } from '@ellure/types';

import { useStore } from '@store';

import { useFormik } from 'formik';

import { Form } from '@components';
import {
  Box,
  useToast,
  Textarea,
  Heading,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Stack,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';

type FormulationValue = {
  amount: number;
  unit: 'g';
  batchId: string;
};

type Formulation = {
  [feedstockId: string]: FormulationValue;
};

type ManualProductInputSchema = {
  productionCompleted: string;
  productionLocation: string;
  productWeight: number;
  formulation: Formulation;
};
function UpdateManualProduct(props: { product: Product; orderId: string; onSubmit?: () => Promise<void> }) {
  const toast = useToast();
  const [store, dispatch] = useStore();
  const form = useFormik<ManualProductInputSchema>({
    initialValues: {
      productionCompleted: new Date().toISOString().split('T')[0],
      productionLocation: store.location.id,
      productWeight: props.product.expected!.weight!,
      formulation: Object.entries(props.product.expected!.formulation!).reduce<Formulation>((obj, item) => {
        obj[item[0]] = {
          amount: item[1].unit === 'g' ? item[1].amount : (item[1].amount / 100) * props.product.expected!.weight!,
          unit: 'g',
          batchId: '',
        };
        return obj;
      }, {}),
    },
    validationSchema: Yup.object().shape({
      productionCompleted: Yup.string().required(),
      productionLocation: Yup.string().required(),
      productWeight: Yup.number().min(0).required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await api.orders.fixManualProduct({
          params: { orderId: props.orderId, productId: props.product.productId },
          query: { type: 'manual' },
          body: {
            completionDate: new Date(values.productionCompleted).getTime(),
            formulation: values.formulation,
            productionLocation: values.productionLocation,
            productWeight: values.productWeight,
          },
        });
        if (props.onSubmit) await props.onSubmit();
        helpers.resetForm();
      } catch (e) {
        toast({
          title: 'Submission failed.',
          description: 'Your action did not succeed.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <>
      <Box m={4} mt={0} bgColor='blue.100' borderRadius='md' p={4}>
        <Heading mb='10px' as='h2' fontSize='lg' fontFamily='arial'>
          Product information
        </Heading>
        <Text>Recipe: {props.product.metadata.derivedFrom.recipe}</Text>
        <Text>Author: {props.product.metadata.derivedFrom.author}</Text>
        <Text>Perfume name: {props.product.metadata.derivedFrom.perfumeName}</Text>
      </Box>
      <Form<ManualProductInputSchema>
        {...form}
        submitLabel='Complete'
        schema={[
          {
            label: 'Production location',
            name: 'productionLocation',
          },
          {
            label: 'Production completed',
            name: 'productionCompleted',
            props: {
              type: 'date',
            },
          },
          {
            label: 'Total product weight',
            name: 'productWeight',
            render: (value, handleChange) => {
              const format = (val: number) => val.toFixed(2) + ' gram';
              const parse = (val: string) => parseFloat(val.replace(/ gram/, ''));

              return (
                <NumberInput
                  onChange={(valueString) => handleChange(parse(valueString))}
                  value={format(value as number)}
                  min={0}
                  step={0.1}
                  precision={2}
                  max={50}
                  variant='filled'
                >
                  <NumberInputField type='text' />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              );
            },
          },
          {
            label: 'Product formulation',
            name: 'formulation',
            render: (value, handleChange) => (
              <FeedstockAmountInputField value={value as Formulation} onChange={handleChange} />
            ),
          },
        ]}
      />
    </>
  );
}

function FeedstockAmountInputField(props: { value: Formulation; onChange: (value: any) => void }) {
  const [feedstockOptions, setFeedstockOptions] = React.useState<Material[]>([]);
  React.useEffect(() => {
    api.resources.materials.get({ query: { page: 0, limit: 60 } }).then((response) => {
      setFeedstockOptions(response.data.data);
    });
  }, []);

  const handleChange = (key: string, value: FormulationValue) => {
    const newValue = JSON.parse(JSON.stringify(props.value));
    newValue[key] = value;
    props.onChange(newValue);
  };

  return (
    <Stack>
      <Table>
        <Thead>
          <Th p={1} pl={3}>
            Feedstock
          </Th>
          <Th p={1} pl={3}>
            Batch
          </Th>
          <Th p={1} pl={3}>
            Quantity
          </Th>
        </Thead>
        <Tbody>
          {Object.entries(props.value).map(([feedstockId, val]) => (
            <FeedstockAmountInputRow
              key={feedstockId}
              refId={feedstockId}
              displayname={feedstockOptions.find((item) => item.materialId === feedstockId)?.displayName}
              value={val}
              onChange={handleChange.bind(null, feedstockId)}
            />
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
}

function FeedstockAmountInputRow(props: {
  value: FormulationValue;
  refId: string;
  displayname?: string;
  onChange: (value: FormulationValue) => void;
}) {
  const format = (val: number) => val.toFixed(2) + ' gram';
  const parse = (val: string) => parseFloat(val.replace(/ gram/, ''));

  const handleChange = (key: string, event: any) => {
    const newValue: FormulationValue = JSON.parse(JSON.stringify(props.value));
    if (key === 'batchId') {
      newValue.batchId = event.target.value;
    } else if (key === 'amount') {
      newValue.amount = event;
    }
    props.onChange(newValue);
  };

  const [batchOptions, setBatchOptions] = React.useState<MaterialBatch[]>([]);
  React.useEffect(() => {
    api.resources.batches
      .get({ query: { page: 0, limit: 60 }, params: { materialId: props.refId } })
      .then((response) => {
        setBatchOptions(response.data.data);
      });
  }, [props.refId]);

  return (
    <Tr>
      <Td p={1} pl={3}>
        <Select isDisabled={true} flex={1} value={props.refId} variant='filled'>
          <option value={props.refId}>{props.displayname}</option>
        </Select>
      </Td>
      <Td p={1} pl={3}>
        <BoxLoader isLoading={batchOptions.length === 0}>
          <Select onChange={handleChange.bind(null, 'batchId')} flex={1} value={props.value.batchId} variant='filled'>
            <option value=''>--SELECT A BATCH--</option>
            {batchOptions.map((item) => (
              <option key={item.batchId} value={item.batchId}>
                {item.batchNumber}
              </option>
            ))}
          </Select>
        </BoxLoader>
      </Td>
      <Td p={1} pl={3}>
        <NumberInput
          onChange={(valueString) => handleChange('amount', parse(valueString))}
          value={format(props.value.amount)}
          min={0}
          step={0.1}
          precision={2}
          max={50}
          variant='filled'
        >
          <NumberInputField type='text' />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Td>
    </Tr>
  );
}

type UnknownProductInputSchema = {
  ingredients: string;
};
function UpdateUnknownProduct(props: { product: Product; orderId: string; onSubmit?: () => Promise<void> }) {
  const toast = useToast();
  const form = useFormik<UnknownProductInputSchema>({
    initialValues: {
      ingredients: (props.product.metadata.derivedFrom.ingredients as string) || '',
    },
    validationSchema: Yup.object().shape({
      ingredients: Yup.string().required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await api.orders.fixUnknownProduct({
          params: { productId: props.product.productId, orderId: props.orderId },
          query: { type: 'ghost' } as any,
          body: values,
        });
        if (props.onSubmit) await props.onSubmit();
        helpers.resetForm();
      } catch (e) {
        toast({
          title: 'Submission failed.',
          description: 'Your action did not succeed.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <>
      <Box m={4} mt={0} bgColor='blue.100' borderRadius='md' p={4}>
        <Heading mb='10px' as='h2' fontSize='lg' fontFamily='arial'>
          Product information
        </Heading>
        <Text>Recipe: {props.product.metadata.derivedFrom.recipe}</Text>
        <Text>Author: {props.product.metadata.derivedFrom.author}</Text>
        <Text>Perfume name: {props.product.metadata.derivedFrom.perfumeName}</Text>
      </Box>
      <Form<UnknownProductInputSchema>
        {...form}
        submitLabel='Complete'
        schema={[
          {
            label: 'Product ingredients',
            name: 'ingredients',
            render: (val, handleChange) => (
              <Textarea
                onChange={(e) => handleChange(e.target.value)}
                value={val as string}
                resize={'none'}
                variant='filled'
              />
            ),
          },
        ]}
      />
      <Text m={4} mt={1} fontSize='xs' color='gray.500'>
        *By filling out the missing information the platform is able to generate the bottle label for this product. The
        platform will however not further process this product as too many parameters are unknown.
      </Text>
    </>
  );
}

export { UpdateManualProduct, UpdateUnknownProduct };
