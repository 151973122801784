import React from 'react';

import { ProductionLocationState } from '@ellure/types';

import { useStore } from '@app:store';
import { useToast } from '@chakra-ui/react';

import { rootHandler, WebSocketSendFunction } from './handlers';

enum ReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

type WebSocketState = {
  send: WebSocketSendFunction;
  reset: () => void;
  readyState: ReadyState;
};

export type ClientActions = {
  "user.deviceResponse": { deviceId:string, requestId:string, actionId:string }
}

export type ServerActions = {
  "location.update": ProductionLocationState,
}

export type WebsocketAction<Actions> = { 
  [K in keyof Actions]: {
    type: K,
    payload: Actions[K];
  } 
}[keyof Actions];

const WebSocketContext = React.createContext<WebSocketState>(undefined!);

export function WebSocketProvider({ uri, children, maxRetries }: { uri: string; maxRetries:number, children: React.ReactNode }) {
  const [store, dispatch] = useStore();
  const toast = useToast();

  const transport = React.useRef<WebSocket | null>(null);
  const [transportState, setTransportState] = React.useState<{ readyState:ReadyState, retries:number }>({
    readyState: ReadyState.CONNECTING,
    retries: 0,
  });

  const onOpen = React.useCallback(
    function(this:WebSocket, e:Event){
      console.log('websocket connected');
      setTransportState(() => ({
        readyState: ReadyState.OPEN,
        retries: 0,
      }));
    }, []
  )

  const send = React.useCallback(
    function(this:WebSocket, action: WebsocketAction<ClientActions>) {
      if (this.readyState !== ReadyState.OPEN) throw Error('Websocket not open');
      console.log('Frontend action send', action);
      this.send(JSON.stringify(action));
    },
    [],
  );

  const onMessage = React.useCallback(
    function(this:WebSocket, rawMessage:MessageEvent){
      const action: WebsocketAction<ServerActions> = JSON.parse(rawMessage.data);
      console.log('Backend action received', action);

      try{
        const handler = rootHandler[action.type].bind(this);
        handler(
          action as any,
          {
            send,
            dispatch,
            toast,
            close: () => {
              this.close();
              setTransportState((v) => ({
                readyState: ReadyState.CLOSING,
                retries: v.retries,
              }));
            }
          }
        );
      } catch(e){
        console.log('websocket message', '--message type could not be handled.');
        console.log(e);
      }
    }, [dispatch, send, toast]
  );

  const onError = React.useCallback(
    function(this:WebSocket, e:Event){
      console.log('websocket error', e);
    }, []
  );

  const onClose = React.useCallback(
    function(this:WebSocket, e:CloseEvent){
      console.log('websocket closed', e);
      this.removeEventListener('open', onOpen);
      this.removeEventListener('message', onMessage);
      this.removeEventListener('error', onError);
      this.removeEventListener('close', onClose);

      if(e.code > 1000 && transportState.retries < maxRetries){
        console.log(
          `Socket is closed. Reconnect will be attempted in 5 second (try ${transportState.retries + 1}).`,
          e.reason,
        );

        toast({
          duration: 5000,
          isClosable: true,
          title: 'Reconnecting',
          description: 'Realtime location data is temporarily unavailable.',
          status: 'warning',
        });

        transport.current = null;
        setTimeout(connect.bind(null, uri+'?token='+store.user.token), 5000);
        setTransportState((v) => ({
          readyState: ReadyState.CONNECTING,
          retries: v.retries + 1,
        }));
      } else {
        toast({
          duration: 5000,
          isClosable: true,
          title: 'Disconnected',
          description: 'Realtime location data is unavailable.',
          status: 'error',
        });

        setTransportState((v) => ({
          readyState: ReadyState.CLOSED,
          retries: v.retries,
        }));
      }
    }, [transportState.retries, maxRetries, uri, store.user.token, toast, onOpen, onError, onMessage]
  );

  const connect = React.useCallback(
    function(url:string){
      const websocket = new WebSocket(url);
      websocket.addEventListener('open', onOpen);
      websocket.addEventListener('message', onMessage);
      websocket.addEventListener('error', onError);
      websocket.addEventListener('close', onClose);
      transport.current = websocket;

      setTimeout(() => { if(websocket.readyState !== ReadyState.OPEN) websocket.close(); }, 3000);
    }, [onOpen, onMessage, onError, onClose]
  );

  React.useEffect(() => {
    console.log('initialize websocket');
    setTimeout(connect.bind(null, uri+'?token='+store.user.token), 2000);
    setTransportState((v) => {
      console.log(`Attempt to connect in 2 second (try ${v.retries + 1}).`);
      return {
        readyState: ReadyState.CONNECTING,
        retries: v.retries + 1,
      }
    });

    return () => {
      if(transport.current && transport.current.readyState === ReadyState.OPEN){
        console.log('close websocket');
        transport.current.close(1000);
        setTransportState((v) => ({
          readyState: ReadyState.CLOSING,
          retries: v.retries,
        }));
      }
    }
  }, [uri, store.user.token]);

  return (
    <WebSocketContext.Provider value={{ 
      readyState: transportState.readyState, 
      send: send.bind(transport.current!),
      reset: () => {
        if(transport.current && transport.current.readyState === ReadyState.OPEN){
          console.log('close websocket');
          transport.current.close(1000);
          setTransportState((v) => ({
            readyState: ReadyState.CLOSING,
            retries: v.retries,
          }));
        } else {
          setTimeout(connect.bind(null, uri+'?token='+store.user.token), 2000);
          setTransportState((v) => {
            console.log(`Attempt to connect in 2 second (try ${v.retries + 1}).`);
            return {
              readyState: ReadyState.CONNECTING,
              retries: 0,
            }
          });
        }
      }, 
    }}>
      {children}
    </WebSocketContext.Provider>
  );
}

WebSocketProvider.defaultProps = {
  maxRetries: 5,
}

function useWebSocket(){
  const context = React.useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebsocket must be used within a Websocket provider');
  }

  return {
    send: context.send,
    connectionState: context.readyState,
  };
}

export { WebSocketContext, ReadyState, useWebSocket };