import React from 'react';

import { useStore } from '@store';

import { Link } from 'react-router-dom';
import {
  Image,
  Center,
  Flex,
  Text,
  Icon,
  Heading,
  Grid,
  GridItem,
  Badge,
  Spacer,
  Box,
  Button,
  Avatar,
} from '@chakra-ui/react';

import {
  RiDropboxLine,
  RiBilibiliLine,
  RiStackLine,
  RiLogoutCircleLine,
  RiSettings2Line,
  RiErrorWarningFill,
  RiZzzFill,
} from 'react-icons/ri';

import logo from '@assets/ellure-logo.png';

const Frame = (props: { sidebarVisible: Boolean; children: React.ReactNode }) => {
  if (!props.sidebarVisible) {
    return (
      <Grid
        minH='100vh'
        gridTemplateColumns={{
          base: '100%',
        }}
        gridTemplateRows={{
          base: '100%',
        }}
        gridTemplateAreas={{
          base: `'main'`,
        }}
      >
        <GridItem gridArea='main' bg='gray.50'>
          <Center alignItems='flex-start' overflowY='auto' h='100%' p={6}>
            {props.children}
          </Center>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Grid
      minH='100vh'
      gridTemplateColumns={{
        base: '15rem auto',
      }}
      gridTemplateRows={{
        base: '100%',
      }}
      gridTemplateAreas={{
        base: `'sider main'`,
      }}
    >
      <GridItem gridArea='sider'>
        <SidebarContent />
      </GridItem>
      <GridItem gridArea='main'>
        <Center alignItems='flex-start' overflowY='auto' h='100vh'>
          {props.children}
        </Center>
      </GridItem>
    </Grid>
  );
};
Frame.defaultProps = {
  sidebarVisible: true,
};

const LinkItems = [
  //{ name: 'Home', href: '/', icon: RiHomeLine },
  {
    name: 'On-site',
    items: [
      { label: 'Orders', href: '/orders', icon: RiDropboxLine },
      { label: 'Devices', href: '/devices', icon: RiBilibiliLine },
    ],
  },
  {
    name: 'Admin',
    items: [
      /*{ label: 'Monitoring', href: '/monitoring', icon: RiLineChartLine },*/
      { label: 'Stock', href: '/resources', icon: RiStackLine },
    ],
  },
];

const SidebarContent = ({ onClose, ...rest }: { onClose?: () => void }) => {
  const [store, dispatch] = useStore();
  const requiresAction = store.location.devices.some((d) => !!d.actionRequest);

  return (
    <Flex
      flexDirection='column'
      bg={'white'}
      borderRight='1px'
      borderRightColor={'gray.200'}
      w='100%'
      h='full'
      pb={4}
      {...rest}
    >
      <Flex flexDirection='column' alignItems='center' mx='14' pt='6' pb='2' justifyContent='space-between'>
        <Image src={logo} alt='Ellure Cloud Factory' />
        <Text>Cloud Factory</Text>
      </Flex>
      <Flex flexDirection='column' alignItems='center' mt='20px'>
        <Text textAlign='center' fontSize='10px' color='gray.400' py='5px' mx='7' textTransform='uppercase'>
          location
        </Text>
        <Heading
          as='h1'
          lineHeight='inherit'
          p={1}
          pr={3}
          size='md'
          fontFamily='Work Sans'
          textAlign='center'
          maxW='85%'
          borderRadius='full'
          backgroundColor='blue.100'
          isTruncated={true}
        >
          <Avatar
            src='https://cdn1.vectorstock.com/i/1000x1000/91/00/science-lab-background-vector-3769100.jpg'
            size='sm'
            name='Segun Adebayo'
            mr={2}
          />
          {store.location.name}
        </Heading>
        <Button size='xs' variant='ghost' colorScheme='blue' isDisabled={true}>
          Switch location
        </Button>
      </Flex>
      {LinkItems.map((category, i) => (
        <React.Fragment key={category.name}>
          <Text
            fontSize='10px'
            mt={i > 0 ? '40px' : '20px'}
            color='gray.400'
            py='5px'
            borderTop='1px solid'
            borderTopColor='gray.200'
            mx='7'
            textTransform='uppercase'
          >
            {category.name}
          </Text>
          {category.items.map((link) => (
            <NavItem key={link.label} icon={link.icon} href={link.href}>
              <Text m='2' ml='0' mr='0' flex={1}>
                {link.label}
              </Text>
              {link.label === 'Devices' && (
                <Box bgColor='white' borderRadius='md' p={2}>
                  <Badge colorScheme='gray' mr='5px'>
                    {store.location.devices.length}
                  </Badge>
                  {requiresAction ? (
                    <Icon as={RiErrorWarningFill} color='red.400' />
                  ) : (
                    <Icon as={RiZzzFill} color='gray.400' />
                  )}
                </Box>
              )}
            </NavItem>
          ))}
        </React.Fragment>
      ))}
      <Spacer />
      <NavItem icon={RiSettings2Line} href={'/settings'}>
        Settings
      </NavItem>
      <NavItem icon={RiLogoutCircleLine} href={'/signout'}>
        Signout
      </NavItem>
    </Flex>
  );
};

const NavItem = ({ icon, href, children, ...rest }: { icon: any; href: string; children: React.ReactNode }) => {
  return (
    <Link to={href} style={{ textDecoration: 'none' }}>
      <Flex
        align='center'
        p='2'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            m='2'
            mr='4'
            fontSize='16'
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

/*<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} /> */

export { Frame };
