import React from 'react';

import { AxiosError } from 'axios';
import api, { Client } from '@ellure/api-client-admin';

import { useStore } from '@app:store';
import { useFormik } from 'formik';

import { Form } from '@components';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';

import { RiTerminalLine } from 'react-icons/ri';
import { SiShopify } from 'react-icons/si';

export default function page() {
  return (
    <Stack spacing={6} w='100%' alignSelf={'center'} alignItems='center'>
      <ShopifySettings />
      <Box bg='white' w='100%' maxWidth='800px' borderColor='gray.200' borderWidth='1px' p={3}>
        <Flex alignItems='center' gap='10px' p='5px' pl={3} borderBottom='2px' borderColor='gray.200'>
          <Icon borderRadius='full' as={RiTerminalLine} h='20px' w='20px' />
          <Heading size='md'>Direct API</Heading>
        </Flex>
        <Flex pt={2}>
          <Alert status='warning'>
            <AlertIcon />
            <AlertTitle>Disabled</AlertTitle>
            <AlertDescription>Contact administrator to enable this function.</AlertDescription>
          </Alert>
        </Flex>
      </Box>
    </Stack>
  );
}

type ShopifySettingsSchema = {
  shop: string;
};

const ShopifySettings = () => {
  const [store, dispatch] = useStore();
  const [isLoading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState<number>(404);

  const checkStatus = React.useCallback(() => {
    setLoading(true);
    api.admin
      .getShopifyStatus({})
      .then((response) => {
        setStatus(response.status);
      })
      .catch((e) => {
        console.log('error in status');
        if (e instanceof AxiosError) setStatus(e.response?.status || 500);
        setStatus(500);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    checkStatus();
    window.addEventListener('focus', checkStatus);
    return () => {
      window.removeEventListener('focus', checkStatus);
    };
  }, []);

  const form = useFormik<ShopifySettingsSchema>({
    initialValues: {
      shop: (store.user.claims.shopify as string) || '',
    },
    onSubmit: async (values) => {
      await api.admin.setShopifyShop({
        body: { shopifyId: values.shop },
      });
      setLoading(true);

      const url =
        [process.env.REACT_APP_API_HOST as string, Client.routes.collect, 'shopify', 'auth'].join('/') +
        `?shop=${values.shop}`;
      window.open(url, '_blank')?.focus();
    },
  });

  console.log(status === 500);

  let content: React.ReactNode;
  if (isLoading)
    content = (
      <Flex flexDirection='column'>
        <Alert mt={2} status='loading'>
          <AlertIcon />
          <AlertDescription>Getting status...</AlertDescription>
        </Alert>
      </Flex>
    );
  else if (status === 200)
    content = (
      <Flex flexDirection='row' alignItems='center' gap={2} mt={2}>
        <Alert status='success'>
          <AlertIcon />
          <AlertTitle>Shopify connected!</AlertTitle>
          <AlertDescription>New orders will be collected automatically.</AlertDescription>
        </Alert>
        {/*<Button colorScheme='red'>Disconnect</Button>*/}
      </Flex>
    );
  else if (status === 500)
    content = (
      <>
        <Flex flexDirection='row' alignItems='center' gap={2} mt={2}>
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Connection error!</AlertTitle>
            <AlertDescription>There was a failure in processing the connection.</AlertDescription>
          </Alert>
        </Flex>
        <Flex pt={2} flexDirection='column'>
          <Form<ShopifySettingsSchema>
            {...form}
            submitLabel='Reconnect'
            schema={[
              {
                label: 'Shop name',
                name: 'shop',
                props: {
                  placeholder: 'shop.myshopify.com',
                },
              },
            ]}
          />
        </Flex>
      </>
    );
  else
    content = (
      <>
        <Flex flexDirection='column' mt={2}></Flex>
        <Flex pt={2} flexDirection='column'>
          <Text pl={4} pr={4}>
            Connect your shopify shop with the Ellure platform to automatically import new orders to be processed
            through the Realization Machines.
          </Text>
          <Form<ShopifySettingsSchema>
            {...form}
            submitLabel='Connect'
            schema={[
              {
                label: 'Shop name',
                name: 'shop',
                props: {
                  placeholder: 'shop.myshopify.com',
                },
              },
            ]}
          />
        </Flex>
      </>
    );

  return (
    <Box bg='white' w='100%' maxWidth='800px' borderColor='gray.200' borderWidth='1px' p={3}>
      <Flex alignItems='center' gap='10px' p='5px' pl={3} borderBottom='2px' borderColor='gray.200'>
        <Icon borderRadius='full' as={SiShopify} h='20px' w='20px' />
        <Heading size='md'>Shopify settings</Heading>
      </Flex>
      {content}
    </Box>
  );
};
