import { StoreAction } from "@app:types";
import { ToastId, UseToastOptions } from "@chakra-ui/react";

import { storeActions } from "@app:store";
import { ClientActions, ServerActions, WebsocketAction } from ".";

export type WebSocketSendFunction = (action:WebsocketAction<ClientActions>) => void;

type EventHandler<Actions, K extends keyof Actions> = (this: WebSocket, action: WebsocketAction<Pick<Actions, K>>, actions:HandlerActions) => void;
type EventHandlerMap<Actions> = {
  [K in keyof Actions]: EventHandler<Actions, K>;
};

type HandlerActions = {
  send: WebSocketSendFunction;
  close: () => void;
  dispatch: (action: StoreAction) => void;
  toast: (options?: UseToastOptions) => ToastId;
};

function onUpdate(this:WebSocket, action:WebsocketAction<Pick<ServerActions, "location.update">>, actions:HandlerActions){
    actions.dispatch({ type: storeActions.SET_DEVICES, payload: action.payload.devices });
}

function onNotify(this:WebSocket, action: { type:string, payload:any }, actions:HandlerActions){
    actions.toast({
        duration: 5000,
        isClosable: true,
        title: action.payload.action,
        description: action.payload.description,
        status: action.payload.type || 'info',
    });
}

export const rootHandler:EventHandlerMap<ServerActions> = {
    "location.update": onUpdate
}