import React from 'react';
import { Center, Grid, GridItem, Heading, Icon } from '@chakra-ui/react';

const SimplePageTemplate = (props) => {
  return (
    <>
      <Grid
        h='100%'
        w='100%'
        gridTemplateColumns={{
          base: '100%',
        }}
        gridTemplateRows={{
          base: '70px 1fr',
        }}
        gridTemplateAreas={{
          base: `'header' 'main'`,
        }}
      >
        <GridItem gridArea='header' p={5} borderColor='gray.200' borderBottomWidth='1px'>
          <Heading as='h1' size='md' lineHeight='auto' isTruncated={true}>
            {props.icon && (
              <Icon
                mr='4'
                fontSize='16'
                _groupHover={{
                  color: 'white',
                }}
                as={props.icon}
              />
            )}
            {props.title}
          </Heading>
        </GridItem>
        <GridItem gridArea='main' p={5} bg='gray.50' h='100%'>
          <Center {...props.contentStyle} height={'100%'}>
            {props.content}
          </Center>
        </GridItem>
      </Grid>
    </>
  );
};

SimplePageTemplate.defaultProps = {
  buttons: [],
  contentStyle: {},
};

export { SimplePageTemplate };
