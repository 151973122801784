import React from 'react';

import api from '@ellure/api-client-admin';
import { DeviceFeedstockInventory, InnerProductUnits, Material, MaterialTypes } from '@ellure/types';
import { ClientResponseTypes } from '@ellure/api-client-admin';

import { useEffectPostMount } from '@utils';
import { useWebSocket } from '@app:services';
import { useFormik } from 'formik';

import { BoxLoader, Form, BatchSelectInput } from '@components';
import { ToastId, useToast } from '@chakra-ui/react';

type FeedstockInputSchema = {
  position1: [string, string];
  position2: [string, string];
  position3: [string, string];
  position4: [string, string];
  position5: [string, string];
  position6: [string, string];
};

export function UpdateFeedstock(props: { machineId: string; feedstock: DeviceFeedstockInventory; onSubmit?: () => void }) {
  const { send } = useWebSocket();
  const toast = useToast();
  const [isLoading, setLoading] = React.useState(true);

  const form = useFormik<FeedstockInputSchema>({
    initialValues: {
      position1: props.feedstock[0] === null ? ["", ""] : [props.feedstock[0].feedstockId, props.feedstock[0].batchId],
      position2: props.feedstock[1] === null ? ["", ""] : [props.feedstock[1].feedstockId, props.feedstock[1].batchId],
      position3: props.feedstock[2] === null ? ["", ""] : [props.feedstock[2].feedstockId, props.feedstock[2].batchId],
      position4: props.feedstock[3] === null ? ["", ""] : [props.feedstock[3].feedstockId, props.feedstock[3].batchId],
      position5: props.feedstock[4] === null ? ["", ""] : [props.feedstock[4].feedstockId, props.feedstock[4].batchId],
      position6: props.feedstock[5] === null ? ["", ""] : [props.feedstock[5].feedstockId, props.feedstock[5].batchId],
    },
    onSubmit: async (values) => {
      setLoading(true);

      const resp = await api.resources.materials.get({ query: { page: 0, limit: 60, type: [MaterialTypes.feedstock] } });

      const payload:DeviceFeedstockInventory = JSON.parse(JSON.stringify(props.feedstock));
      const _values = Object.values(values);
      for(let i = 0; i < 6; i++){
        const [feedstockId, batchId] = _values[i];

        payload[i] = {
          feedstockId,
          batchId,
          displayname: resp.data.data.find((material) => material.materialId === feedstockId)?.displayName || "",
          quantity: {
            initial: { amount: 53, unit: InnerProductUnits.GRAM, },
            used: { amount: 0, unit: InnerProductUnits.GRAM, },
          }
        }
      }

      console.log(payload);

      send({
        type: "user.deviceResponse",
        payload: {
          deviceId: props.machineId,
          requestId: 'feedstock-update',
          actionId: ""//payload,
        },
      });

      if (typeof props.onSubmit === 'function') props.onSubmit();
    },
  });

  console.log(form.initialValues);

  useEffectPostMount(() => {
    let id: ToastId | undefined = undefined;

    if (isLoading) {
      form.setValues({
        position1: props.feedstock[0] === null ? ["", ""] : [props.feedstock[0].feedstockId, props.feedstock[0].batchId],
        position2: props.feedstock[1] === null ? ["", ""] : [props.feedstock[1].feedstockId, props.feedstock[1].batchId],
        position3: props.feedstock[2] === null ? ["", ""] : [props.feedstock[2].feedstockId, props.feedstock[2].batchId],
        position4: props.feedstock[3] === null ? ["", ""] : [props.feedstock[3].feedstockId, props.feedstock[3].batchId],
        position5: props.feedstock[4] === null ? ["", ""] : [props.feedstock[4].feedstockId, props.feedstock[4].batchId],
        position6: props.feedstock[5] === null ? ["", ""] : [props.feedstock[5].feedstockId, props.feedstock[5].batchId],
      });

      id = toast({
        title: 'Feedstock updated.',
        description: 'Device has handled the update successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setLoading(false);
    }

    return () => {
      if (!id) return;
      if (toast.isActive(id)) toast.close(id);
    };
  }, [props.feedstock]);

  const [feedstockOptions, setFeedstockOptions] = React.useState<Material[]>([]);
  React.useEffect(() => {
    api.resources.materials.get({ query: { page: 0, limit: 60, type: [MaterialTypes.feedstock] } }).then((response) => {
      setFeedstockOptions(response.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <BoxLoader isLoading={isLoading}>
      <Form<FeedstockInputSchema>
        {...form}
        submitLabel='Update'
        schema={[
          {
            label: 'Feedstock at position 1',
            name: 'position1',
            render: (value, handleChange) => {
              return <BatchSelectInput value={value} onChange={handleChange} feedstockOptions={feedstockOptions} />;
            },
          },
          {
            label: 'Feedstock at position 2',
            name: 'position2',
            render: (value, handleChange) => {
              return <BatchSelectInput value={value} onChange={handleChange} feedstockOptions={feedstockOptions} />;
            },
          },
          {
            label: 'Feedstock at position 3',
            name: 'position3',
            render: (value, handleChange) => {
              return <BatchSelectInput value={value} onChange={handleChange} feedstockOptions={feedstockOptions} />;
            },
          },
          {
            label: 'Feedstock at position 4',
            name: 'position4',
            render: (value, handleChange) => {
              return <BatchSelectInput value={value} onChange={handleChange} feedstockOptions={feedstockOptions} />;
            },
          },
          {
            label: 'Feedstock at position 5',
            name: 'position5',
            render: (value, handleChange) => {
              return <BatchSelectInput value={value} onChange={handleChange} feedstockOptions={feedstockOptions} />;
            },
          },
          {
            label: 'Feedstock at position 6',
            name: 'position6',
            render: (value, handleChange) => {
              return <BatchSelectInput value={value} onChange={handleChange} feedstockOptions={feedstockOptions} />;
            },
          },
        ]}
      />
    </BoxLoader>
  );
}
