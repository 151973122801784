import { Slice, UserClaims } from '@app:types';
import { authenticationActions } from './authentication.actions';

import { Client } from '@ellure/api-client-admin';

export type AuthenticationState = {
  isAuthenticated: boolean;
  token: string | null;
  claims: UserClaims;
};

const slice: Slice<AuthenticationState> = {
  name: 'user',
  initialState: {
    isAuthenticated: false,
    token: null,
    claims: {
      companyId: null,
    }
  },
  reducers: {
    [authenticationActions.SET_AUTHENTICATED]: function(state, payload:boolean){
      state.isAuthenticated = payload;
    },
    [authenticationActions.SET_TOKEN]: function(state, payload:{ token: string | null, claims?: UserClaims }){
      state.token = payload.token;
      state.isAuthenticated = !!payload.token ? true : false;
      if(payload.claims) state.claims = payload.claims;
      
      if(state.isAuthenticated) Client.server.defaults.headers.common['x-api-key'] = payload.token;
      else {
        delete Client.server.defaults.headers.common['x-api-key'];
        state.claims = slice.initialState.claims;
      }
    },
    [authenticationActions.SET_CLAIMS]: function(state, payload: { claims: UserClaims }){
      state.claims = payload.claims;
    }
  },
};

export default slice;
