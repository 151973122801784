import React from 'react';

import { useStore } from '@store';

import { CreateOrderForm, OrderInputSchema } from '@forms';
import { PendingOrders, ProducedOrders } from './lists';

import { SimplePageTemplate, Modal } from '@components/layout';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Button, Spacer, useDisclosure, ButtonGroup } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RiDropboxLine } from 'react-icons/ri';

const limit = 10;
const Content = () => {
  const navigate = useNavigate();
  const [store, dispatch] = useStore();
  const [params] = useSearchParams();

  const [refresh, setRefresh] = React.useState(false);
  const [formData, setFormData] = React.useState<Partial<OrderInputSchema> | undefined>(undefined);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {store.location.id === '64e89d6c5022c77ae86a4355' && (
        <ButtonGroup mb={2} pb={2} pt={2} width='100%'>
          <Button
            size='lg'
            colorScheme='blue'
            onClick={() => {
              setFormData({
                content: [
                  {
                    productNumber: '',
                    recipe: 'face-oil-sample',
                    author: 'visitor',
                    perfumeName: 'face-oil sample',
                    qrAccess: 'adfadfad',
                  },
                ],
              });
              console.log(formData);
              onOpen();
            }}
          >
            Create Sample
          </Button>
          <Button
            size='lg'
            colorScheme='blue'
            onClick={() => {
              setFormData({
                content: [
                  {
                    productNumber: '',
                    recipe: 'face-oil-refill',
                    author: 'visitor',
                    perfumeName: 'face-oil refill',
                    qrAccess: 'adfadfad',
                  },
                ],
              });
              onOpen();
            }}
          >
            Create Refill
          </Button>
        </ButtonGroup>
      )}
      <Tabs
        index={parseInt(params.get('view') || '0')}
        variant='enclosed'
        width='100%'
        height='100%'
        display='flex'
        flexDir='column'
        onChange={(index) => {
          params.set('view', index.toString());
          navigate({
            pathname: '',
            search: params.toString(),
          });
        }}
      >
        <TabList>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Pending orders</Tab>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Produced orders</Tab>
          <Spacer />
          <Button mb='10px' colorScheme='blue' onClick={onOpen}>
            Create order
          </Button>
        </TabList>

        <TabPanels bg='white' borderColor='gray.200' borderWidth='1px' flex={1}>
          <TabPanel height='100%'>
            <PendingOrders refresh={refresh} limit={limit} />
          </TabPanel>
          <TabPanel height='100%'>
            <ProducedOrders limit={limit} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setFormData(undefined);
          onClose();
        }}
        title='Create new order'
        modalContentStyle={{ maxWidth: '90%', width: '800px' }}
      >
        <CreateOrderForm
          onSubmit={async () => {
            setFormData(undefined);
            onClose();
            setRefresh(!refresh);
          }}
          prefillData={formData}
        />
      </Modal>
    </>
  );
};

const page = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Manage incoming orders',
        icon: RiDropboxLine,
      }}
      content={<Content />}
      contentStyle={{ flexDir: 'column' }}
    />
  );
};

export default page;
