import ReactDOM from 'react-dom/client';

import { initializeApp } from 'firebase/app';
import { initializeApi } from '@ellure/api-client-admin';

import { useStickyState } from '@utils/hooks';

import StoreProvider, { Store } from '@app:store';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import theme from './@theme/theme';

initializeApp({
  apiKey: 'AIzaSyAPesAxKHfwG9yWONi4BvZ0scCASMFUyRI',
  authDomain: 'strong-skyline-360612.firebaseapp.com',
  projectId: 'strong-skyline-360612',
  storageBucket: 'strong-skyline-360612.appspot.com',
  messagingSenderId: '837864713928',
  appId: '1:837864713928:web:596e4629424ae84ac0fffd',
});

initializeApi({
  baseURL: process.env.REACT_APP_API_HOST as string,
});

const storeVersion = '0.1.0';
const Root = () => {
  const [state, setState] = useStickyState<Store>(undefined!, `adminStore_${storeVersion}`);
  return (
    <StoreProvider savedState={state} onSave={setState}>
      <ChakraProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ChakraProvider>
    </StoreProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Root />);
reportWebVitals();
