import { StoreAction, Slice } from '@app:types';

import Authentication from './authentication/authentication.slice';
import Locations from './locations/locations.slice';

export type Store = {
  user: (typeof Authentication)['initialState'];
  location: (typeof Locations)['initialState'];
};

export const slices: Slice<any>[] = [Authentication, Locations];

export const defaultState = slices.reduce((map, slice) => {
  map[slice.name as keyof Store] = slice.initialState;
  return map;
}, {} as Store);

export function rootReducer(state: Store, action: StoreAction) {
  const stateCopy = JSON.parse(JSON.stringify(state)) as Store;
  for (const slice of slices) {
    if (action.type in slice.reducers) {
      let subState = stateCopy[slice.name as keyof Store];
      slice.reducers[action.type](subState, action.payload);
    }
  }

  return stateCopy;
}

/*import { Store, StoreAction } from '@app:types';
import storeActions from './actions';

export default function reducer(state: Store, action: StoreAction): Store {
  console.log('action', action);
  switch (action.type) {
    case storeActions.SET_DEVICES:
      return { ...state, state: { ...state.state, devices: [...action.payload] } };

    case storeActions.SET_USER:
      return action.payload
        ? { ...state, auth: { ...state.auth, isAuthenticated: true } }
        : { ...state, auth: { ...state.auth, isAuthenticated: false } };

    case storeActions.SET_LOCATION:
      return {
        ...state,
        state: {
          ...state.state,
          locationId: action.payload.id || state.state.locationId,
          locationName: action.payload.name || '...',
        },
      };

    default:
      return state;
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}*/
