import { DeviceStatus, Device } from '@ellure/types';

import { useStore } from '@app:store';
import { useWebSocket } from '@app:services';

import { UpdateFeedstock } from '@forms';

import { SimplePageTemplate, Modal } from '@components/layout';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Progress,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { RiBilibiliLine, RiMore2Fill, RiCloudLine, RiSignalWifiOffLine } from 'react-icons/ri';

const MachineQuickDisplay = (props: Device) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { send } = useWebSocket();

  const performAction = (actionId: string) => {
    if (!props.actionRequest) return;
    send({
      type: "user.deviceResponse",
      payload: {
        deviceId: props.id,
        requestId: props.actionRequest.requestId,
        actionId,
      },
    });
  };

  const { productData, actionRequest, settings, status } = props;

  return (
    <Box bg='white' w='100%' maxWidth='800px' borderColor='gray.200' borderWidth='1px'>
      <Flex>
        <Flex flex='3' flexDir='column'>
          <Flex alignItems='center' gap='10px' p='5px' pl={3} borderBottom='2px' borderColor='gray.200'>
            <Icon borderRadius='full' as={RiBilibiliLine} h='20px' w='20px' />
            <Heading size='md'>{settings.name}</Heading>
            <Spacer />
            <ButtonGroup>
              <Button size='sm' variant='ghost' onClick={onOpen} isDisabled={status === DeviceStatus.OFFLINE}>
                <RiMore2Fill />
              </Button>
            </ButtonGroup>
          </Flex>
          {(props as any).actionProgress && (
            <Flex alignItems='center' borderColor='gray.200' borderWidth='1px'>
              <Progress border={0} size='lg' hasStripe flex={1} value={(props as any).actionProgress.value} bg='white' />
            </Flex>
          )}
          <Flex alignItems='center' gap={6} height='100%' minHeight='100px'>
            {actionRequest ? (
              <>
                <ButtonGroup gap='2px' bg={'gray.400'} height='100%'>
                  {actionRequest.actions.map((action) => (
                    <Button
                      key={action.label}
                      style={{ margin: 0 }}
                      borderRadius={0}
                      h='100%'
                      w='100px'
                      onClick={performAction.bind(null, action.actionId)}
                    >
                      {action.label}
                    </Button>
                  ))}
                </ButtonGroup>
                <Flex flex={1} flexDir='column'>
                  <Text fontSize={'xs'}>
                    <i>Action required</i>
                  </Text>
                  <Text>
                    <b>{actionRequest.message}</b>
                  </Text>
                </Flex>
              </>
            ) : (
              <>
                <ButtonGroup gap='0' height='100%'>
                  <Flex alignItems='stretch'>
                    <Button borderRadius={0} h='100%' w='100px' disabled>
                      {status === DeviceStatus.OFFLINE ? <RiSignalWifiOffLine /> : <Spinner size='xs' />}
                    </Button>
                  </Flex>
                </ButtonGroup>
                <Flex flex={1} flexDir='column'>
                  <Text fontSize={'xs'}>
                    <i>Machine status</i>
                  </Text>
                  <Text>{status}</Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex width='40%' alignItems='center' borderColor='gray.200' borderWidth='1px'>
          <Box bgColor='blue.100' p={6} width='100%' height='100%'>
            <Heading mb='10px' as='h2' fontSize='lg' fontFamily='arial'>
              Product information
            </Heading>
            {productData ? (
              <>
                <Text isTruncated>Recipe: {productData.metadata.derivedFrom.recipe}</Text>
                <Text isTruncated>Author: {productData.metadata.derivedFrom.author}</Text>
                <Text isTruncated>Perfume name: {productData.metadata.derivedFrom.perfumeName}</Text>
              </>
            ) : (
              <Text isTruncated>No product pending.</Text>
            )}
          </Box>
        </Flex>
      </Flex>
      <Modal title={`${props.settings.name} feedstock settings`} isOpen={isOpen} onClose={onClose}>
        <UpdateFeedstock machineId={props.id} feedstock={props.feedstock} />
      </Modal>
    </Box>
  );
};

const Content = () => {
  const [store, dispatch] = useStore();
  const devices: Device[] = store.location.devices;

  return (
    <Stack spacing={6} w='100%' alignSelf={devices.length > 0 ? 'flex-start' : 'center'} alignItems='center'>
      {devices.length > 0 ? (
        devices.map((device) => <MachineQuickDisplay {...device} key={device.id} />)
      ) : (
        <Box opacity={0.3}>
          <Text textAlign={'center'}>
            <Icon h={'80px'} w={'80px'} as={RiCloudLine} /> <br />
            Waiting for devices to register...
          </Text>
        </Box>
      )}
    </Stack>
  );
};

const page = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Devices',
        icon: RiBilibiliLine,
      }}
      content={<Content />}
    />
  );
};

export default page;
