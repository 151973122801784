import React from 'react';

import { StoreAction } from '@app:types';
import { Store, defaultState, rootReducer } from './reducer';

type StoreState = {
  state: Store;
  dispatch: (action: StoreAction) => void;
};
const StoreContext = React.createContext<StoreState>(undefined!);

export default function StoreProvider({
  onSave,
  savedState,
  children,
}: {
  onSave?: (state: Store) => void;
  savedState?: Store;
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(rootReducer, Object.assign({}, defaultState));
  //console.log('state', state);

  React.useEffect(() => {
    if (typeof onSave === 'function') onSave(state);
  }, [state, onSave]);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
}

export function useStore(): [Store, (action: StoreAction) => void] {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a Store Provider.');
  }

  return [context.state, context.dispatch];
}

export * from './actions';
export type { Store };

/*type StoreState = {
  state: Store;
  dispatch: (action: StoreAction) => void;
};

const defaultStore: Store = {
  state: {
    locationId: 'undefined',
    locationName: 'My location',
    devices: [],
  },
  auth: {
    token: null,
    isManager: false,
    isAuthenticated: false,
  },
};

const StoreContext = React.createContext<StoreState>(undefined!);

export default function StoreProvider({
  onSave,
  state,
  children,
  ...props
}: {
  onSave?: (state: Store) => void;
  state?: Store;
  children: React.ReactNode;
}) {
  const [preState, nativeDispatch] = React.useReducer(storeReducer, Object.assign({}, defaultStore));
  console.log('prestate', preState);

  const postState = Object.assign({}, preState, {});
  console.log('poststate', postState);

  const dispatch = React.useCallback(
    (action: StoreAction) => {
      switch (action.type) {
        default:
          nativeDispatch(action);
      }
    },
    [nativeDispatch],
  );

  React.useEffect(() => {
    if (typeof onSave === 'function') onSave(preState);
  }, [preState, onSave]);

  return <StoreContext.Provider value={{ state: postState, dispatch }}>{children}</StoreContext.Provider>;
}
StoreProvider.defaultProps = {
  state: {},
  onSave: null,
};

function useStore(): [Store, (action: StoreAction) => void] {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a AppStore');
  }

  return [context.state, context.dispatch];
}

export {
  storeActions,
  StoreContext,
  useStore,
};

export type {
  Store,
  StoreAction
}*/
