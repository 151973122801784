import * as Yup from 'yup';
import api from '@ellure/api-client-admin';

import { useFormik } from 'formik';

import { Form, ProductsInput, ProductInputSchema } from '@components';
import { useToast } from '@chakra-ui/react';

export type OrderInputSchema = {
  name: string;
  email: string;
  orderDate: string;
  shopifyId: string;
  content: ProductInputSchema[];
};
export function CreateOrderForm(props: { onSubmit?: () => Promise<void>; prefillData?: Partial<OrderInputSchema> }) {
  const toast = useToast();
  const form = useFormik<OrderInputSchema>({
    initialValues: {
      name: '',
      email: '',
      orderDate: new Date().toISOString().split('T')[0],
      shopifyId: '',
      content: props.prefillData?.content || [
        {
          author: '',
          perfumeName: '',
          productNumber: '30--01',
          qrAccess: '',
          recipe: '',
        },
      ],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().required(),
      orderDate: Yup.string().required(),
      shopifyId: Yup.string().required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await api.fulfillment.createOrder({
          body: {
            shopifyId: values.shopifyId,
            metadata: {
              email: values.email,
              name: values.name,
              orderDate: new Date(values.orderDate).toISOString(),
            },
            content: values.content,
          },
          query: { type: 'perfume' },
        });
        if (props.onSubmit) await props.onSubmit();
        helpers.resetForm();
      } catch (e) {
        toast({
          title: 'Submission failed.',
          description: 'Your action did not succeed.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <>
      <Form<OrderInputSchema>
        {...form}
        submitLabel='Create'
        schema={[
          {
            label: 'Customer name',
            name: 'name',
          },
          {
            label: 'Customer email',
            name: 'email',
          },
          {
            label: 'Order date',
            name: 'orderDate',
            props: {
              type: 'date',
            },
          },
          {
            label: 'Ordernumber',
            name: 'shopifyId',
          },
          {
            label: 'Products',
            name: 'content',
            render: (value, handleChange) => (
              <ProductsInput
                orderNumber={form.values.shopifyId}
                value={value as ProductInputSchema[]}
                onChange={handleChange}
              />
            ),
          },
        ]}
      />
    </>
  );
}
