import React from 'react';

import api from '@ellure/api-client-admin';
import { Order, PendingOrder, PendingProduct, Product } from '@ellure/types';

import { useStore } from '@store';
import { useEffectPostMount } from '@utils';

import { UpdateManualProduct, UpdateUnknownProduct } from '@forms';

import { BoxLoader, Modal } from '@components/layout';
import { ListView, Sorting } from '@components/data-display';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import {
  RiDownload2Line,
  RiSettings2Fill,
  RiArrowUpFill,
  RiInformationFill,
  RiMore2Fill,
  RiCheckFill,
} from 'react-icons/ri';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { SortingKeys } from '@ellure/api-client-admin/build/@api/register';

function ContentRender(props: { orderId: string; data: PendingProduct[]; onUpdate: () => void }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const [isLoading, setLoading] = React.useState(false);

  const [automaticProducts, manualProducts, unknownProducts, requestedProducts, completedProducts] = props.data.reduce<
    [Product[], Product[], Product[], Product[], Product[]]
  >(
    (categories, p) => {
      if (p._status !== 'fulfilled') {
        if (p._status === 'completed') categories[4].push(p);
        else if (p._status === 'requested') categories[3].push(p);
        else {
          if ((p.metadata as any).processedAs === 'ghost') categories[2].push(p);
          else if ((p.metadata as any).processedAs === 'automatic') categories[0].push(p);
          else categories[1].push(p);
        }
      }

      return categories;
    },
    [[], [], [], [], []],
  );

  return (
    <BoxLoader isLoading={isLoading}>
      <HStack>
        {requestedProducts.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='gray'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
              onClick={onOpen2}
            >
              <Flex w='100%'>
                <Text flex='1'>Waiting...</Text>
                <Spinner size='sm' />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Products are in production
              </Text>
            </Button>
          </Box>
        )}
        {automaticProducts.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='blue'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              cursor='pointer'
              onClick={() => {
                setLoading(true);
                api.orders
                  .dispatch({ params: { orderId: props.orderId }, body: {} })
                  .then(() => {
                    props.onUpdate();
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              <Flex w='100%'>
                <Text flex='1'>{automaticProducts.length} automatic</Text>
                <RiArrowUpFill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to send to machines
              </Text>
            </Button>
          </Box>
        )}
        {manualProducts.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='yellow'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
              onClick={onOpen}
            >
              <Flex w='100%'>
                <Text flex='1'>{manualProducts.length} manual</Text>
                <RiSettings2Fill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to process
              </Text>
            </Button>
          </Box>
        )}
        {unknownProducts.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='red'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
              onClick={onOpen2}
            >
              <Flex w='100%'>
                <Text flex='1'>{unknownProducts.length} not processed</Text>
                <RiInformationFill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to add label info
              </Text>
            </Button>
          </Box>
        )}
        {completedProducts.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='green'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
              onClick={() => {
                setLoading(true);
                api.orders
                  .done({ params: { orderId: props.orderId } })
                  .then(() => {
                    props.onUpdate();
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              <Flex w='100%'>
                <Text flex='1'>{completedProducts.length} completed</Text>
                <RiCheckFill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to mark as fulfilled
              </Text>
            </Button>
          </Box>
        )}
      </HStack>
      {manualProducts.length > 0 && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          title={`Product ${manualProducts[0].metadata.derivedFrom.productNumber}`}
          modalContentStyle={{ maxW: '800px', w: '90%' }}
        >
          <UpdateManualProduct
            product={manualProducts[0]}
            orderId={props.orderId}
            onSubmit={async () => {
              onClose();
              props.onUpdate();
            }}
          />
        </Modal>
      )}
      {unknownProducts.length > 0 && (
        <Modal
          isOpen={isOpen2}
          onClose={onClose2}
          title={`Product ${unknownProducts[0].metadata.derivedFrom.productNumber}`}
        >
          <UpdateUnknownProduct
            product={unknownProducts[0]}
            orderId={props.orderId}
            onSubmit={async () => {
              onClose2();
              props.onUpdate();
            }}
          />
        </Modal>
      )}
    </BoxLoader>
  );
}

function ActionRender(props: { orderId: string; data: PendingOrder; onUpdate: () => void }) {
  const [store, dispatch] = useStore();

  const [loading, setLoading] = React.useState(false);
  const toast = useToast();

  return (
    <BoxLoader isLoading={loading}>
      <ButtonGroup>
        {store.location.id === '6411a11c467ba9ef2a8fcdee' && (
          <Button
            title='Download labels'
            onClick={async () => {
              try {
                setLoading(true);
                await api.download(
                  `${process.env.REACT_APP_API_HOST}/api/register/pending/orders/${props.orderId}/labels`,
                );
              } catch (e) {
                console.log('error', e);
                toast({
                  duration: 3000,
                  status: 'error',
                  title: 'Error downloading labels',
                  description: 'Please try again or contact the administrator.',
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            <RiDownload2Line />
            <Text fontSize='xs' ml='10px'>
              Labels
            </Text>
          </Button>
        )}
        <Menu>
          <MenuButton as={IconButton} icon={<RiMore2Fill />} variant='outline' aria-label='options' />
          <MenuList>
            <MenuItem
              disabled={true}
              onClick={async () => {
                try {
                  setLoading(true);
                  await api.orders.updatePending({
                    body: {},
                    query: {
                      method: 'processor',
                    },
                    params: {
                      orderId: props.orderId,
                    },
                  });
                  props.onUpdate();
                } catch (e) {
                  console.log('error', e);
                  toast({
                    duration: 3000,
                    status: 'error',
                    title: 'Error updating order',
                    description: 'Please try again or contact the administrator.',
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Process order again
            </MenuItem>
            <MenuItem
              isDisabled={props.data.content.every((item) => item.metadata.processedAs !== 'automatic')}
              onClick={async () => {
                try {
                  setLoading(true);
                  await api.orders.updatePending({
                    body: {
                      processManually: true,
                    },
                    query: {
                      method: 'local',
                    },
                    params: {
                      orderId: props.orderId,
                    },
                  });
                  props.onUpdate();
                } catch (e) {
                  console.log('error', e);
                  toast({
                    duration: 3000,
                    status: 'error',
                    title: 'Error updating order',
                    description: 'Please try again or contact the administrator.',
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Process order manually
            </MenuItem>
            <MenuItem
              onClick={async () => {
                try {
                  setLoading(true);
                  await api.orders.deletePending({
                    params: {
                      orderId: props.orderId,
                    },
                  });
                  props.onUpdate();
                } catch (e) {
                  console.log('error', e);
                  toast({
                    duration: 3000,
                    status: 'error',
                    title: 'Error deleting order',
                    description: 'Please try again or contact the administrator.',
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Delete order
            </MenuItem>
          </MenuList>
        </Menu>
      </ButtonGroup>
    </BoxLoader>
  );
}

export function PendingOrders(props: { limit: number; refresh: boolean }) {
  const [refresh, setRefresh] = React.useState(false);

  async function getData(currentPage: number, sorting:Sorting[]) {
    
    const dateSort = sorting[1] === Sorting.ASC ? SortingKeys.ORDER_DATE : SortingKeys.ORDER_DATE_DESC;

    const result = await api.orders.getPending({
      query: { page: currentPage, limit: props.limit, showAll: false, sort: [ dateSort ] },
    });
    
    return result.data;
  }

  useEffectPostMount(() => {
    setRefresh((v) => !v);
  }, [props.refresh]);

  return (
    <ListView<PendingOrder>
      limit={props.limit}
      emptyMessage={'No pending orders'}
      dataApi={getData}
      dataId='orderId'
      refresh={refresh}
      title='Pending Order List'
      columns={[
        {
          key: 'orderNumber',
          title: 'Order Number',
          props: {
            width: '10%',
          },
        },
        {
          key: 'metadata.orderDate',
          title: 'Order Date',
          render: (data: string) => {
            const date = new Date(data);
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
            return `${ye}-${mo}-${da}`;
          },
          props: {
            width: '10%',
          },
        },
        {
          key: 'metadata.name',
          title: 'Creator',
          props: {
            width: '10%',
          },
        },
        {
          key: 'content',
          title: 'Products to fulfill',
          render: (data, item) => {
            return (
              <ContentRender
                orderId={item.orderId}
                data={data}
                onUpdate={() => {
                  setRefresh(!refresh);
                }}
              />
            );
          },
        },
        {
          key: '@actions',
          title: 'Actions',
          render: (data, item) => (
            <ActionRender
              orderId={item.orderId}
              data={item}
              onUpdate={() => {
                setRefresh(!refresh);
              }}
            />
          ),
          props: {
            width: '200px',
          },
        },
      ]}
      defaultSort={[Sorting.NONE, Sorting.ASC, Sorting.NONE, Sorting.NONE, Sorting.NONE]}
    />
  );
}

export function MenuRender(props: { orderId: string; data: Order; onUpdate: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();

  const baseURL = `${process.env.REACT_APP_API_HOST}/api/register/fulfilled/orders/${props.orderId}`;

  const downloadDocumentation = async (type: string) => {
    try {
      setLoading(true);
      await api.download(`${baseURL}/documentation?type=${type}`);
    } catch (e) {
      console.log('error', e);
      toast({
        duration: 3000,
        status: 'error',
        title: 'Error downloading file',
        description: 'Please try again or contact the administrator.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BoxLoader isLoading={loading}>
      <ButtonGroup>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <RiDownload2Line />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={downloadDocumentation.bind(null, '*')}>All</MenuItem>
            <MenuItem onClick={downloadDocumentation.bind(null, 'PIF')}>PIF</MenuItem>
            <MenuItem onClick={downloadDocumentation.bind(null, 'CPSR')}>CPSR</MenuItem>
            <MenuItem onClick={downloadDocumentation.bind(null, 'label')}>Labels</MenuItem>
          </MenuList>
        </Menu>
        {/*<Menu>
          <MenuButton as={IconButton} icon={<RiMore2Fill/>} variant="outline" aria-label="options"  />
          <MenuList>
            <MenuItem onClick={async () => {
              try{
                setLoading(true);
                await api.orders.deleteProduced({
                  params: {
                    orderId: props.orderId,
                  }
                });
                props.onUpdate();
              } catch(e){
                console.log("error", e);
                toast({ duration: 3000, status: 'error', title: "Error deleting order", description: "Please try again or contact the administrator." });
              } finally {
                setLoading(false);
              }
            }}>Delete order</MenuItem>
          </MenuList>
        </Menu>*/}
      </ButtonGroup>
    </BoxLoader>
  );
}

export function ProducedOrders(props: any) {
  const [refresh, setRefresh] = React.useState(false);

  async function getData(currentPage: number) {
    const result = await api.orders.getProduced({
      query: { page: currentPage, limit: props.limit },
    });
    return result.data;
  }

  useEffectPostMount(() => {
    setRefresh((v) => !v);
  }, [props.refresh]);

  return (
    <ListView<Order>
      limit={props.limit}
      emptyMessage={'No produced orders'}
      dataApi={getData}
      dataId='orderId'
      title='Produced Order List'
      refresh={refresh}
      columns={[
        {
          key: 'orderNumber',
          title: 'Order Number',
          props: {
            width: '10%',
          },
        },
        {
          key: 'metadata.orderDate',
          title: 'Order Date',
          render: (data: string) => {
            const date = new Date(data);
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
            return `${ye}-${mo}-${da}`;
          },
          props: {
            width: '10%',
          },
        },
        {
          key: 'metadata.name',
          title: 'Creator',
          props: {
            width: '10%',
          },
        },
        {
          key: 'content.length',
          title: '# of Products',
        },
        {
          key: '@actions',
          title: 'Actions',
          render: (data, item) => (
            <MenuRender
              orderId={item.orderId}
              data={item}
              onUpdate={() => {
                setRefresh(!refresh);
              }}
            />
          ),
          props: {
            width: '200px',
          },
        },
      ]}
    />
  );
}
