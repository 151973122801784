import React from 'react';

import api from '@ellure/api-client-admin';
import { FirebaseError } from 'firebase/app';
import * as firebase from 'firebase/auth';

import { storeActions, useStore } from '@store';
import { useEffectPostMount } from '@utils';

import Signin from './signin';
import ChangePassword from './change-password';
import CompletePassword from './complete-password';

import { Frame, BoxLoader } from '@components/layout';

export enum AuthFlowType {
  SIGNIN = 'signin',
  COMPLETEPASSWORD = 'complete-password',
  CHANGEPASSWORD = 'change-password',
  RESETPASSWORD = 'reset-password',
  AUTHENTICATED = 'authenticated',
}

export enum AuthErrorMessages {
  TOKEN_ERROR = 'Authentication could not be completed, please contact an administrator.',
  USER_RETRIEVAL_ERROR = 'User data could not be retrieved correctly, please contact an administrator.',
  INVALID_INPUT = 'The form contains errors.',
  INVALID_CREDENTIALS = 'Username/password does not match, please try again.',
}

export type SignupConfig = {
  username: string;
  password: string;
};

type AuthState = {
  signup: (config: SignupConfig) => Promise<void>;
  signin: (username: string, password: string) => Promise<void>;
  signout: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  error?: string;
  user: {
    current: firebase.User | null,
    refresh: (forceRefresh:boolean) => void,
  }
};

const AuthContext = React.createContext<AuthState>(undefined!);

export function AuthProvider({ children, ...props }: { children: React.ReactNode }) {
  const [isLoading, setLoading] = React.useState(true);
  const [store, dispatch] = useStore();

  const tokenRefreshTimer = React.useRef<NodeJS.Timer>();
  const [authFlowType, setAuthFlowType] = React.useState<AuthFlowType>(AuthFlowType.SIGNIN);
  const [error, setError] = React.useState('');

  const user = firebase.getAuth().currentUser;

  const signup = React.useCallback(async function ({ username, password }: SignupConfig) {
    setLoading(true);
    try {
      await firebase.createUserWithEmailAndPassword(firebase.getAuth(), username, password);
    } catch (error) {
      console.log('error signing up:', error);
      throw new Error('Error signing up');
    } finally {
      setLoading(false);
    }
  }, []);

  const signin = React.useCallback(async function (email: string, password: string) {
    setLoading(true);
    try {
      await firebase.signInWithEmailAndPassword(firebase.getAuth(), email, password);
    } catch (e) {
      console.log(e);
      if (e instanceof FirebaseError) {
        switch (e.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
            throw new Error(AuthErrorMessages.INVALID_CREDENTIALS);
          case 'auth/internal-error':
            throw new Error(AuthErrorMessages.INVALID_INPUT);
        }
      }
      throw new Error('Unknown error occurred. Please contact an administrator.');
    } finally {
      setLoading(false);
    }
  }, []);

  const signout = React.useCallback(async function () {
    setLoading(true);
    try {
      await firebase.getAuth().signOut();
    } catch (error) {
      console.log('error signing out: ', error);
      throw new Error('Error signing out');
    } finally {
      setLoading(false);
    }
  }, []);

  const changePassword = React.useCallback(
    async function (newPassword: string, emailOrOldPassword: string) {
      setLoading(true);
      try {
        await firebase.updatePassword(user!, newPassword);
        setAuthFlowType(AuthFlowType.AUTHENTICATED);
      } catch (e) {
        console.log('error changing password: ', e);
        throw new Error('Error changing password');
      } finally {
        setLoading(false);
      }
    },
    [user],
  );

  const refreshToken = React.useCallback(
    async function (user: firebase.User | null, forceRefresh:boolean = false) {
      if(!user) return;
      const result = await user.getIdTokenResult(forceRefresh);
      const expirationTime = new Date(result.expirationTime);
      expirationTime.setMinutes(expirationTime.getMinutes() - 5);

      if(tokenRefreshTimer.current) clearTimeout(tokenRefreshTimer.current);
      tokenRefreshTimer.current = setTimeout(() => {
        const user = firebase.getAuth().currentUser;
        refreshToken(user, true);
      }, expirationTime.getTime() - Date.now());

      dispatch({ type: storeActions.SET_TOKEN, payload: { token: result.token, claims: { companyId: result.claims.companyId, userId: result.claims.user_id }, }});
      if(result.claims.locationId && result.claims.locationName)
        dispatch({ type: storeActions.SET_LOCATION, payload: { id: result.claims.locationId, name: result.claims.locationName, } });
    }, [dispatch]
  );

  React.useEffect(() => {
    const unsubscribe = firebase.getAuth().onIdTokenChanged((user) => {
      setLoading(true);

      if(!user){
        dispatch({ type: storeActions.SET_TOKEN, payload: { token: null, }});
        dispatch({ type: storeActions.SET_LOCATION, payload: { id: null, }});

        setAuthFlowType(AuthFlowType.SIGNIN);
        setLoading(false);
        return;
      }

      refreshToken(user)
        .catch((e) => {
          console.log('error');
          console.log(e);
          setAuthFlowType(AuthFlowType.SIGNIN);
          setError(AuthErrorMessages.TOKEN_ERROR);
        })
        .finally(() => {
          setAuthFlowType(AuthFlowType.AUTHENTICATED);
          setLoading(false);
        });
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        signup,
        signin,
        signout,
        changePassword,
        error,
        user: {
          current: user,
          refresh: refreshToken.bind(null, user),
        }
      }}
    >
      <BoxLoader isLoading={isLoading}>
        {authFlowType === AuthFlowType.AUTHENTICATED 
          ? <AccountFlow>{children}</AccountFlow> 
          : <AuthFlow type={authFlowType} />}
      </BoxLoader>
    </AuthContext.Provider>
  );
}

const AuthFlow = ({ type }:{ type:AuthFlowType }) => {
  let AuthFrame = Signin;
  switch (type) {
    case AuthFlowType.COMPLETEPASSWORD:
      AuthFrame = CompletePassword;
      break;
    case AuthFlowType.CHANGEPASSWORD:
      AuthFrame = ChangePassword;
      break;
  }

  return (
    <Frame sidebarVisible={false}>
      <AuthFrame />
    </Frame>
  );
};

const AccountFlow = function({ children, ...props }: { children: React.ReactNode }){
  const [isLoading, setLoading] = React.useState(false);
  const [isReady, setReady] = React.useState(false);

  const [store, dispatch] = useStore();
  const { user } = useAuth();

  useEffectPostMount(() => {
    if(user.current){
      if(!store.user.claims.companyId) {
        setLoading(true);
        api.admin.setCompany({})
          .then(() => {
            user.refresh(true);
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      if(!store.location.id) {
        setLoading(true);
        api.admin.setLocation({})
          .then(() => {
            user.refresh(true);
          })
          .finally(() => {
            setLoading(false);
          });
        
        return;
      }

      setReady(true);
    }
  }, [user.current, user.refresh, store.user.isAuthenticated, store.user.claims.companyId, store.location.id])

  return <BoxLoader h={!isReady ? '100vh' : undefined} isLoading={isLoading}>
    {isReady ? children : []}
  </BoxLoader>;
}

function useAuth(): AuthState {
  const context = React.useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within a Authentication provider');
  return context;
}

export { AuthContext, useAuth, };
