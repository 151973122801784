import '@fontsource/libre-caslon-display';
import '@fontsource/work-sans';

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
    },
    'pigment-Red': {
      500: '#ff0000',
    },
    'pigment-Yellow': {
      500: '#fffF00',
    },
    'pigment-Blue': {
      500: '#0000ff',
    },
    'pigment-Black': {
      500: '#000000',
    },
    'pigment-White': {
      500: '#eeeeee',
    },
    'pigment-Base': {
      500: '#dedbc1',
    },
  },
  fonts: {
    heading: 'Libre Caslon Display, sans-serif',
    body: 'Work Sans, sans-serif',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: '300',
      },
    },
  },
  global: {
    body: {
      margin: 0,
      padding: 0,
      fontFamily: 'sans-serif',
    },
  },
});

export default theme;
