import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { MaterialList, ProductTemplateList, MaterialSupplierList } from '@lists';
import { CreateMaterial, CreateProductTemplateForm, CreateSupplierForm } from '@forms';

import { SimplePageTemplate, Modal } from '@components/layout';
import { Tabs, TabList, Tab, TabPanels, TabPanel, useDisclosure } from '@chakra-ui/react';

import { RiStackLine } from 'react-icons/ri';

const limit = 10;
const Content = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [refreshToggle, refresh] = React.useState(false);
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

  return (
    <>
      <Tabs
        index={parseInt(params.get('view') || '0')}
        variant='enclosed'
        width='100%'
        height='100%'
        display='flex'
        flexDir='column'
        onChange={(index) => {
          params.set('view', index.toString());
          navigate({
            pathname: '',
            search: params.toString(),
          });
        }}
      >
        <TabList>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Templates</Tab>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Feedstock</Tab>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Suppliers</Tab>
        </TabList>

        <TabPanels bg='white' borderColor='gray.200' borderWidth='1px' flex={1}>
          <TabPanel height='100%'>
            <ProductTemplateList
              limit={limit}
              refresh={refreshToggle}
              headerActions={[
                {
                  label: 'Create product',
                  onClick: () => {
                    onOpen1();
                  },
                  colorScheme: 'blue',
                },
              ]}
            />
          </TabPanel>
          <TabPanel height='100%'>
            <MaterialList
              limit={limit}
              refresh={refreshToggle}
              headerActions={[
                {
                  label: 'Create feedstock',
                  onClick: () => {
                    onOpen2();
                  },
                  colorScheme: 'blue',
                },
              ]}
            />
          </TabPanel>
          <TabPanel height='100%'>
            <MaterialSupplierList
              limit={limit}
              refresh={refreshToggle}
              headerActions={[
                {
                  label: 'Create supplier',
                  onClick: () => {
                    onOpen3();
                  },
                  colorScheme: 'blue',
                },
              ]}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal
        title='Create a new product template'
        isOpen={isOpen1}
        onClose={onClose1}
        modalContentStyle={{ maxW: '800px', w: '90%' }}
      >
        <CreateProductTemplateForm
          onSubmit={() => {
            onClose1();
            refresh(!refreshToggle);
          }}
        />
      </Modal>
      <Modal title='Create a new feedstock' isOpen={isOpen2} onClose={onClose2}>
        <CreateMaterial
          onSubmit={() => {
            onClose2();
            refresh(!refreshToggle);
          }}
        />
      </Modal>
      <Modal title='Create a new supplier' isOpen={isOpen3} onClose={onClose3}>
        <CreateSupplierForm
          onSubmit={() => {
            onClose3();
            refresh(!refreshToggle);
          }}
        />
      </Modal>
    </>
  );
};

const page = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Manage products and materials',
        icon: RiStackLine,
      }}
      content={<Content />}
    />
  );
};

export default page;
