import React from 'react';

import { Stack, Table, Thead, Th, Tbody, Button, Tr, Td, Input, Badge } from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';

import { RiDeleteBin2Line } from 'react-icons/ri';

export type ProductInputSchema = {
  recipe: string;
  productNumber: string;
  qrAccess: string;
  perfumeName: string;
  author: string;
};

export function ProductsInput(props: {
  value: ProductInputSchema[];
  orderNumber: string;
  onChange: (value: ProductInputSchema[]) => void;
}) {
  const handleChange = (index: number, value: ProductInputSchema) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  const handleDelete = (index: number) => {
    if (props.value.length > 1) {
      const newValue = [...props.value];
      newValue.splice(index, 1);
      props.onChange(
        newValue.map((p, i) => ({
          ...p,
          productNumber: `30-${props.orderNumber}-${(i + 1).toString().padStart(2, '0')}`,
        })),
      );
    }
  };

  const { orderNumber, onChange } = props;
  React.useEffect(() => {
    onChange(
      props.value.map((p, i) => ({
        ...p,
        productNumber: `30-${orderNumber}-${(i + 1).toString().padStart(2, '0')}`,
      })),
    );
  }, [orderNumber]);

  return (
    <BoxLoader>
      <Stack>
        <Table>
          <Thead>
            <Th w='100px' p={1} pl={3}>
              Product #
            </Th>
            <Th w='100px' p={1} pl={3}>
              Template
            </Th>
            <Th w='150px' p={1} pl={3}>
              Creator name
            </Th>
            <Th w='150px' p={1} pl={3}>
              Product name
            </Th>
            <Th p={1} pl={3}>
              QR code
            </Th>
            <Th w='50px' p={1} pl={3} />
          </Thead>
          <Tbody>
            {props.value.map((val, index) => (
              <OrderProductsInputRow
                key={index}
                value={val}
                onChange={handleChange.bind(null, index)}
                onDelete={handleDelete.bind(null, index)}
              />
            ))}
          </Tbody>
        </Table>
        <Button
          onClick={() => {
            const newValue = [...props.value];
            newValue.push({
              author: '',
              perfumeName: '',
              productNumber: `30-${props.orderNumber}-${(newValue.length + 1).toString().padStart(2, '0')}`,
              qrAccess: '',
              recipe: '',
            });
            props.onChange(newValue);
          }}
        >
          + Add product
        </Button>
      </Stack>
    </BoxLoader>
  );
}

function OrderProductsInputRow(props: {
  value: ProductInputSchema;
  onChange: (value: ProductInputSchema) => void;
  onDelete: () => void;
}) {
  const handleChange = (key: string, event: any) => {
    const newValue = JSON.parse(JSON.stringify(props.value));
    newValue[key] = event.target.value;
    props.onChange(newValue);
  };

  return (
    <Tr>
      <Td p={1} pl={3}>
        <Badge>{props.value.productNumber}</Badge>
      </Td>
      <Td p={1} pl={3}>
        <Input value={props.value.recipe} onChange={handleChange.bind(null, 'recipe')} />
      </Td>
      <Td p={1} pl={3}>
        <Input value={props.value.author} onChange={handleChange.bind(null, 'author')} />
      </Td>
      <Td p={1} pl={3}>
        <Input value={props.value.perfumeName} onChange={handleChange.bind(null, 'perfumeName')} />
      </Td>
      <Td p={1} pl={3}>
        <Input value={props.value.qrAccess} onChange={handleChange.bind(null, 'qrAccess')} />
      </Td>
      <Td p={1} pl={3}>
        <Button variant='ghost' p={0} size='sm' onClick={props.onDelete}>
          <RiDeleteBin2Line />
        </Button>
      </Td>
    </Tr>
  );
}
