import { Slice, Device } from '@app:types';
import { locationActions } from './locations.actions';

export type LocationsState = {
  id: string;
  name: string;
  devices: Device[];
};

const slice: Slice<LocationsState> = {
  name: 'location',
  initialState: {
    id: null!,
    name: 'Loading...',
    devices: [],
  },
  reducers: {
    [locationActions.SET_LOCATION]: function (state: LocationsState, payload: { id?: string; name?: string }) {
      state.id = payload.id || slice.initialState.id;
      state.name = payload.name || slice.initialState.name;
    },
    [locationActions.SET_DEVICES]: function (state: LocationsState, payload: Device[]) {
      state.devices = payload;
    },
  },
};

export default slice;
