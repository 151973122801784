import React from 'react';

import api from '@ellure/api-client-admin';

import { Material, MaterialBatch } from '@ellure/types';

import { BoxLoader } from '@components/layout';
import { Flex, Select, Text } from '@chakra-ui/react';

export function BatchSelectInput(props: {
  value: [string, string];
  onChange: (value: [string, string]) => void;
  feedstockOptions: Material[];
}) {
  const handleChange = (key: string, event: any) => {
    console.log(event.target.value);
    const newValue: [string, string] = [props.value[0], props.value[1]];
    if (key === 'materialId') newValue[0] = event.target.value;
    if (key === 'batchId') newValue[1] = event.target.value;
    props.onChange(newValue);
  };

  const materialId = props.value[0];
  const [isLoading, setLoading] = React.useState(false);
  const [batchOptions, setBatchOptions] = React.useState<MaterialBatch[]>([]);
  React.useEffect(() => {
    if (materialId === '') return;

    setLoading(true);
    api.resources.batches
      .get({ query: { page: 0, limit: 60 }, params: { materialId } })
      .then((response) => {
        setBatchOptions(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [materialId]);

  return (
    <BoxLoader isLoading={isLoading}>
      <Flex alignItems='center' border='1px solid #eee'>
        <Text p='5px' flex='1'>
          Feedstock
        </Text>
        <Select flex='3' onChange={handleChange.bind(null, 'materialId')} value={props.value[0]} variant='filled'>
          <option value=''>--Select a feedstock--</option>
          {props.feedstockOptions.map((item) => (
            <option key={item.materialId} value={item.materialId}>
              {item.displayName}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems='center' border='1px solid #eee' mt='2px'>
        <Text p='5px' flex='1'>
          Batch
        </Text>
        <Select
          flex='3'
          disabled={batchOptions.length === 0}
          onChange={handleChange.bind(null, 'batchId')}
          value={props.value[1]}
          variant='filled'
        >
          <option value=''>--Select a batch--</option>
          {batchOptions.map((item) => (
            <option key={item.batchId} value={item.batchId}>
              {item.batchNumber}
            </option>
          ))}
        </Select>
      </Flex>
    </BoxLoader>
  );
}
